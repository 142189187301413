import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import { linkUnderline } from 'style/global.styles';
import { breakpoints } from 'style/variables';

export const grid = css`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxxlrg);

  &.footer__info-section {
    grid-template-columns: repeat(2, 1fr);
    row-gap: var(--spacing-xhuge);

    @media ${breakpoints.medium} {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-xxxlrg);

    &.footer__cta-section {
      .cta-section__title {
        grid-column: 1 / span 5;
      }

      .cta-section__form {
        grid-column: 7 / -1;
      }
    }

    &.footer__info-section {
      .info-section__roango {
        grid-column: 1 / span 2;
      }

      .info-section__connect {
        grid-column: 3 / span 2;
      }

      .info-section__reach-out {
        grid-column: 5 / span 2;
      }

      .info-section__legal {
        grid-column: 7 / span 2;
      }

      .info-section__address {
        grid-column: -1;
      }
    }

    &.footer__copyright-section {
      h1 {
        grid-column: 1 / span 2;
      }

      .copyright-section__description {
        grid-column: 3 / span 4;
      }
    }
  }
`;

export const footer = css`
  background: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-grayscale-1));
  padding: ${spacing(80)} 0 ${spacing(60)};

  .footer__wrapper {
    max-width: 1312px;
    margin: 0 auto;
    padding-left: var(--spacing-xlrg);
    padding-right: var(--spacing-xlrg);
    box-sizing: content-box;
  }

  .footer__divider {
    border: none;
    border-top: 1px solid hsl(var(--color-borderDark-5));
  }
`;

export const footerInfoSection = css`
  padding-bottom: var(--spacing-xhuge);

  @media ${breakpoints.medium} {
    padding-bottom: calc(var(--unit) * 25);
  }

  .info-section__title {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-tiny);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-tiny);
    text-transform: uppercase;
    letter-spacing: 1.25px;
    color: hsl(var(--color-borderDark-5));
    margin-bottom: calc(var(--unit) * 7);
  }

  .info-section__content {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-med);
  }

  .info-section__item {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-small);
    color: hsl(var(--color-borderUltraLight-10));
    width: max-content;

    ${linkUnderline};
    &:after {
      height: 1px;
    }
  }

  .info-section__legal {
    .info-section__item {
      color: hsl(var(--color-border-7));
    }
  }
`;

export const link = css`
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  color: hsl(var(--color-borderUltraLight-10));
  width: max-content;

  ${linkUnderline};
  &:after {
    height: 1px;
  }
`;

export const justifyBetween = css`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

  @media ${breakpoints.large} {
    display: flex;
    justify-content: space-between;
    padding: 0 calc(var(--unit) * 50) 0 calc(var(--unit) * 36);
  }
`;

export const logo = css`
  svg {
    width: calc(var(--unit) * 18);
  }
`;

export const year = css`
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  margin: 0;
`;

export const newsletter = css`
  margin-bottom: calc(var(--unit) * 20);

  @media ${breakpoints.large} {
    margin-bottom: calc(var(--unit) * 25);
  }

  .cta-section__title {
    display: none;
    font-size: var(--font-size-xxLarge);
    font-family: var(--font-family-secondary);
    line-height: var(--line-height-xxLarge);
    font-weight: normal;

    @media ${breakpoints.medium} {
      display: block;
    }
  }
`;

export const formWrapper = css`
  width: 100%;

  @media ${breakpoints.large} {
    margin: 0;
    max-width: calc(var(--unit) * 150);
  }

  form {
    width: 100%;
  }

  #mc_embed_signup_scroll {
    margin: calc(var(--unit) * 8) 0;
    width: 100%;
    display: flex;
    border-bottom: 1px solid hsl(var(--color-borderDark-5));
    padding-bottom: 12px;

    @media ${breakpoints.large} {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: 0;
      border-bottom: none;
      padding-bottom: unset;
      margin-bottom: calc(var(--unit) * 8);
    }
  }

  .mc-field-group {
    width: 100%;
    margin-right: calc(var(--unit) * 8);
  }

  label[for='checkbox-terms'] {
    a {
      color: inherit;
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;

export const formLabel = css`
  text-transform: uppercase;
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
`;

export const formInput = css`
  background: transparent;
  border: none;

  outline: none;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-family: var(--font-family-primary);
  font-weight: 700;
  margin-top: calc(var(--unit) * 3);
  width: 100%;
  color: hsl(var(--color-grayscale-1));

  @media ${breakpoints.large} {
    font-size: var(--font-size-large);
    line-height: var(--line-height-large);
    border-bottom: 1px solid hsl(var(--color-borderDark-5));

    :active,
    :focus {
      border-bottom: 1px solid hsl(var(--color-grayscale-1));
    }
  }
`;

export const info = css`
  margin: 0;
  margin-top: calc(var(--unit) * 5);
`;

export const formSubmit = css`
  ${buttonStyles.root}
  ${buttonStyles.ghost}
  ${buttonStyles.smallExtended}
  border: 1px solid hsl(var(--color-grayscale-2));
  color: hsl(var(--color-grayscale-1));
  margin-top: calc(var(--unit) * 5);

  @media ${breakpoints.medium} {
    ${buttonStyles.mediumExtended}
    width: auto;
  }
`;

export const termsAndConditions = css`
  label {
    align-items: flex-start;

    // Select Checkbox
    span:first-of-type {
      flex: 0 0 ${spacing(16)};
      width: ${spacing(16)};
      height: ${spacing(16)};
    }

    // Select text label
    span:last-of-type {
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
    }
  }
`;

export const roangoText = css`
  margin-bottom: 0;
  color: hsl(var(--color-borderDark-5));
`;

export const cardsContainer = css`
  display: flex;

  @media ${breakpoints.medium} {
    grid-column: -1;
  }
`;

export const companyInfo = css`
  font-size: var(--font-size-small);
  color: hsl(var(--color-textLight-7));
`;
